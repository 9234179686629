@import url('https://fonts.googleapis.com/css?family=Montserrat+Alternates:bold');
@import url('https://fonts.googleapis.com/css?family=Montserrat+Alternates');
@import url('https://fonts.googleapis.com/css?family=Raleway:bold');
@import url('https://fonts.googleapis.com/css?family=Raleway');

@import url('https://fonts.googleapis.com/css?family=Inter:bold');
@import url('https://fonts.googleapis.com/css?family=Inter');

body {
  margin: 0;
  font-family: "Raleway";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fffefb;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='250' height='30' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%23FFFEF7' stroke-width='10' %3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E");
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  all: unset;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.PhoneInputInput {
  flex: 2;
  padding: 1rem;
  margin: 1rem 1rem 1rem 0rem;
  background: rgba(255, 239, 215, 0.5);
  border: none;
  border-radius: 3px;
  font-size: 0.95rem;
  min-width: 300px;
  color: #F06B62;
}

.PhoneInputCountry {
  padding: 16px;
  margin: 16px;
  padding-left: 1rem;
  margin-left: 1rem !important;
  margin-right: 0rem !important;
  padding-right: 0px;
  background: rgba(255, 239, 215, 0.5);
  height: auto;
}

.Collapsible {
  margin-top: 1rem;
  max-width: 1000px;
  width: 70%;
  border: 1px solid #F06F56;
  /* background: rgba(255, 239, 215, 0.2); */
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.Collapsible__trigger {
  width: 100%;
  padding: 10px 20px 10px 20px;
  margin-right: 20px;
  color: #000000;
  font-size: 25px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}